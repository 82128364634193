export default {
  state: {
    user_data: null,
  },
  getters: {},
  mutations: {},
  actions: {
    async actionSignIn({rootGetters, commit, dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/login',
          authorization: false,
          method: 'POST',
          post_data: data
        });

        const result = request.result;
        const ok = request.ok;

        if (ok) {
          localStorage.setItem('token', result.token);
          dispatch('actionGetUserByToken');
          return Promise.resolve();
        } else {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          return Promise.reject(result)
        }
      } catch (err) {
      }

    },

    /**
     * Login (registration) user by Google (gsi) client
     * @returns {Promise<void>}
     */
    async actionSignInByGoogle({commit, dispatch}, data) {

      try {
        const request = await dispatch('actionRequest', {
          uri: '/login-by-google',
          authorization: false,
          method: 'POST',
          post_data: data
        });

        const result = request.result;
        // console.log(result);
        localStorage.setItem('token', result.token);
        dispatch('actionGetUserByToken');
        // return Promise.resolve();

      } catch (err) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        // return Promise.reject(err)
      }
    },
    async actionSignInByLinkedin({commit, dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/login-by-linkedin',
          authorization: false,
          method: 'POST',
          post_data: data
        });

        const result = request.result;

        localStorage.setItem('token', result.token);
        dispatch('actionGetUserByToken');
        // return Promise.resolve(result.response);

      } catch (err) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        // return Promise.reject(err)
      }
    },
    async actionGetTokenFromLinkedin({commit, dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/get-token-from-linkedin',
          authorization: false,
          method: 'POST',
          post_data: data
        });

        const result = request.result;

        // localStorage.setItem('token', result.token);
        // dispatch('actionGetUserByToken');
        return Promise.resolve(result.response);

      } catch (err) {
        // localStorage.removeItem('user');
        // localStorage.removeItem('token');
        return Promise.reject(err)
      }
    },

    async actionSignUp({rootGetters, commit, dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/sign-up',
          authorization: false,
          method: 'POST',
          post_data: data
        });

        const result = request.result;
        const ok = request.ok;

        if (ok) {
          const message = result.message;
          dispatch('alert', {message, type: 'info'});
          return Promise.resolve(result);
        } else {
          return Promise.reject(result)
        }
      } catch (err) {
        return Promise.reject(err);
      }

    },

    async actionGetUserByEmailToken({dispatch}, token) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/get-user-data-by-email-token/' + token,
          authorization: false,
        });
        const result = request.result;
        const ok = request.ok;
        if (ok) {
          const user = result.user;
          return Promise.resolve(user);
        }

      } catch (err) {
        // console.error(err);
      }
    },

    async actionRecoverPassword({rootGetters, commit, dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/recover-password',
          authorization: false,
          method: 'POST',
          post_data: data
        });

        const result = request.result;
        const ok = request.ok;
        if (ok) {
          return Promise.resolve(result);
        }

      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionUpdateUserPassword({rootGetters, commit, dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/update-user-password',
          authorization: false,
          method: 'POST',
          post_data: data
        });

        const result = request.result;
        const ok = request.ok;
        if (ok) {
          return Promise.resolve(result);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionLogOut({commit, dispatch}) {
      const request = await dispatch('actionRequest', {
        uri: "/logout",
        method: 'GET',
      });
      const result = request.result;
      const ok = request.ok;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      // localStorage.removeItem('clientListParameters');
      document.cookie = "user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.expresspermits.llc; path=/";
      console.log("Cookie removed");
      commit('mutation_store_user', null);
      return Promise.resolve(result);
    },

  },

};