<template>
  <div class="sign-up-form" v-if="!success && !fail">
    <!-- <div v-if="!!user_id" class="form-title">Your email has been confirmed.</div> -->
    <div class="form-title">Sign Up to getting started</div>
    <div class="form-elements">
      <div class="form-grid r-2">

        <div class="form-element">
          <input-text v-model="signUpForm.name" placeholder="Name" :rules="rules.textOnlyLetters" @error="(err) => {
            regError('name', err);
          }
          " />
        </div>
        <div class="form-element">
          <input-text v-model="signUpForm.lastname" placeholder="Last Name" :rules="rules.textOnlyLetters" @error="(err) => {
            regError('name', err);
          }
          " />
        </div>
      </div>
      <div class="form-element">
        <input-email v-model="signUpForm.email" placeholder="example@example.com" autocomplete="both"
          :disabled="!!user_id" :rules="rules.requiredEmail" @error="(err) => {
            regError('email', err);
          }
          " />
      </div>

      <div class="form-element">
        <input-phone v-model="signUpForm.phone" placeholder="phone" autocomplete="both" :rules="rules.phone"
          :maxlength="14" @error="(err) => {
            regError('phone', err);
          }
          " />
      </div>

      <div class="form-element">
        <input-password v-model="signUpForm.password" placeholder="Password" :rules="rules.password" :pass-gen="true"
          @generated="copyGeneratedPassword" @error="err => {
            regError('password', err);
          }" @input="() => {
            regError('password', false);
          }" />
      </div>
      <div class="form-element">
        <input-password v-model="signUpForm.confirm_password" placeholder="Confirm password"
          @blur="checkPasswordIdentical" @input="passwordsMatch = true" />
          <span v-if="!passwordsMatch" class="err not-match">Passwords do not match</span>
      </div>
    </div>
    <div class="form-element row agreement">
      <input-checkbox v-model="signUpForm.agreement"
        label='By clicking "Sign Up", you read and agree to our <a target="_blank" href="https://expresspermits.llc/service-agreement/">Service Agreement</a> and <a target="_blank" href="https://expresspermits.llc/privacy-policy/">Privacy Policy.</a>' />
    </div>

    <button class="button" @click="onSignUp" :disabled="!isStepValid">
      sign up
    </button>
  </div>
  <div v-if="!success && !fail" class="check-account">
    <span>Already have an account?</span>
    <button class="button-link" @click="onSignIn">
      Sign In
    </button>
  </div>
  <sign-up-success v-if="success" />
  <sign-up-failed v-if="fail" />
</template>

<script>
import InputText from "@/components/form-elements/InputText.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import InputEmail from "@/components/form-elements/InputEmail.vue";
import InputPhone from "@/components/form-elements/InputPhone.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";
import InputCheckbox from "@/components/form-elements/InputCheckbox.vue";
import SignUpSuccess from "@/components/authorization/SignUpSuccess.vue";
import SignUpFailed from "@/components/authorization/SignUpFailed.vue";
import ValidateMixin from "@/mixins/ValidateMixin";

export default {
  name: "SignUpForm",
  components: { InputText, InputEmail, InputPhone, InputCheckbox, InputPassword, SignUpSuccess, SignUpFailed, Spinner },
  mixins: [ValidateMixin],
  data() {
    return {
      isLoading: false,
      success: false,
      fail: false,
      passwordsMatch: true,
      user_id: null,
      disabled: false,
      signUpForm: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        password: null,
        confirm_password: null,
        agreement: false,
      },
      errors: {
        name: false,
        lastname: false,
        email: false,
        phone: false,
        password: false,
        agreement: false,
      },
    };
  },
  mounted() {
    // const route_name = this.$route.name;
    // if (route_name === 'sign_up_token') {
    //   const token = this.$route.params.token;
    //   if (token.length !== 32) {
    //     this.$store.dispatch('alert', { message: 'Wrong token', type: 'info' });
    //   } else {
    //     this.$store.dispatch('actionGetUserByEmailToken', token)
    //       .then(user => {
    //         this.user_id = user.user_id;
    //         this.signUpForm.name = user.name;
    //         this.signUpForm.lastname = user.lastname;
    //         this.signUpForm.phone = user.phone;
    //         this.signUpForm.email = user.email;
    //       });
    //   }
    // }


  },
  emits: ['updateSignIn'],
  computed: {

    isPasswordsIdentical() {
      // if (!this.user_id) {
      //   return true;
      // }
      const form = this.signUpForm;
      if (!form.password || !form.confirm_password) {
        return false;
      }
      if (form.password.trim() !== form.confirm_password.trim()) {
        return false;
      }
      return true;
    },

    isStepValid() {
      const user_id = this.user_id;
      if (!this.signUpForm.agreement) {
        return false;
      }
      const existError = Object.keys(this.errors).find((el) => this.errors[el]);
      let emptyFieldsArr = Object.keys(this.signUpForm).filter(key => {
        // if (!user_id) {
        if ((key === 'password') || (key === 'confirm_password') || (key === 'agreement')) {
          return false;
        }
        // }
        return !this.signUpForm[key];
      });
      const isEmptyField = !!emptyFieldsArr.length;
      return !existError && !isEmptyField && this.isPasswordsIdentical && !this.disabled;
    }
  },
  methods: {
    onSignUp() {
      this.disabled = true;
      this.$store.dispatch("actionSignUp", {
        // user_id: this.user_id,
        name: this.signUpForm.name,
        lastname: this.signUpForm.lastname,
        phone: this.signUpForm.phone,
        login: this.signUpForm.email,
        password: this.signUpForm.password,
        agreement: this.signUpForm.agreement,
      })
        .then((response) => {
          this.disabled = false;
          // this.success = true;
          if(response.result) {
            this.fail = false;
            const data = {
              email: this.signUpForm.email,
              password: this.signUpForm.password,
              remember_me: false,
            };
            this.$store.dispatch("actionSignIn", data).then((response) => {
              this.$router.push({ name: "new_order" });
            })
              
          }
            this.resetForm();
        });
    },
    resetForm() {
      const form = this.signUpForm;
      form.name = null;
      form.lastname = null;
      form.email = null;
      // form.phone = null;
      form.password = null;
      form.confirm_password = null;
      form.agreement = false;

      const errors = this.errors;
      errors.name = false;
      errors.lastname = false;
      errors.email = false;
      errors.phone = false;
      errors.password = false;
      errors.agreement = false;

      this.user_id = null;

      this.passwordsMatch = true;
    },
    copyGeneratedPassword(pass) {
      this.errors.password = false;
      this.passwordsMatch = true;
      this.signUpForm.confirm_password = pass;
    },
    onSignIn() {
      this.resetForm();
      this.$router.push('/');
    },

    checkPasswordIdentical() {
      setTimeout(() => {
        this.passwordsMatch = !!this.isPasswordsIdentical;
      });
    }

  },
};
</script>
