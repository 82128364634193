import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/homePage/HomePage";
import AdminEquipmentPage from "@/pages/adminEquipmentPage/adminEquipmentPage";
import EquipmentPage from "@/pages/equipmentPage/EquipmentPage";
import NewOrderPage from "@/pages/newOrderPage/NewOrderPage";
import OrderPage from "@/pages/orderPage/OrderPage";
import OrderPageId from "@/pages/orderPageIdPage/OrderPageId";
import AdminOrderPage from "@/pages/adminOrderPage/AdminOrderPage";
import AdminOrderPageId from "@/pages/adminOrderPageIdPage/AdminOrderPageId";
import ClientsPage from "@/pages/clientsPage/ClientsPage";
import ClientActivities from "@/pages/clientActivitiesPage/ClientActivities";
import ProfilePage from "@/pages/profilePage/ProfilePage";
import UsersPage from "@/pages/usersPage/UsersPage";
import BtPage from "@/pages/btPage/BtPage";
import CompaniesPage from "@/pages/companiesPage/CompaniesPage";
import SuperAdminOrdersPage from "@/pages/superAdminOrdersPage/SuperAdminOrdersPage";
import PasswordReset from "@/pages/passwordReset/PasswordReset";
import { useStore } from "vuex";
const routes = [
  {
    path: "/",
    name: "home",
    page_name: "Home",
    component: HomePage,
    meta: { title: "Accounting - Express Permits LLC" },
  },

  {
    path: "/password-reset/",
    name: "password_recover",
    page_name: "Password Recover",
    component: PasswordReset,
    meta: { title: "Password Recover" },
  },
  {
    path: "/password-reset/:token/",
    name: "password_reset",
    page_name: "Password Reset",
    component: PasswordReset,
    meta: { title: "Password Reset" },
  },
  {
    path: "/invite-user/:token/",
    name: "invite_user",
    page_name: "Invite User",
    meta: { title: "Invite User" },
  },
  {
    path: "/sign-up/",
    name: "sign_up",
    page_name: "Sign Up",
    meta: { title: "Sign Up to  Express Permits LLC Accounting." },
  },
  {
    path: "/sign-up/:token/",
    name: "sign_up_token",
    page_name: "Sign Up",
    meta: { title: "Sign Up to  Express Permits LLC Accounting." },
  },
  {
    path: "/equipment/",
    name: "equipment",
    page_name: "Equipment",
    component: EquipmentPage,
    meta: { title: "Equipment Page - Express Permits LLC" },
  },
  {
    path: "/admin-equipment/",
    name: "admin-equipment",
    page_name: "Equipment",
    component: AdminEquipmentPage,
    meta: { title: "Admin Equipment Page - Express Permits LLC" },
  },
  {
    path: "/new-order/",
    name: "new_order",
    page_name: "New Order",
    component: NewOrderPage,
    meta: { title: "New Order Page - Express Permits LLC" },
  },
  {
    path: "/order/",
    name: "order",
    page_name: "Orders History Page",
    component: OrderPage,
    meta: { title: "Orders History Page - Express Permits LLC" },
  },
  {
    path: "/order/:order_id",
    name: "order-single",
    page_name: "Order Page",
    component: OrderPageId,
    meta: { title: "Order Page - Express Permits LLC" },
  },
  {
    path: "/admin-order/:order_id",
    name: "admin-order-single",
    page_name: "Admin Order Page ",
    component: AdminOrderPageId,
    meta: { title: "Agent Order Page - Express Permits LLC" },
  },
  {
    path: "/admin-order/",
    name: "admin_order",
    page_name: "Admin - Order Page",
    component: AdminOrderPage,
    meta: { title: "Agent Orders Page - Express Permits LLC" },
  },
  {
    path: "/clients/",
    name: "clients",
    page_name: "Clients Page",
    component: ClientsPage,
    meta: { title: "Clients Page - Express Permits LLC" },
  },
  {
    path: "/client-activities/",
    redirect: "/clients/",
    meta: { title: "Clients Activities Page - Express Permits LLC" },
  },
  {
    path: "/client-activities/:user_id",
    name: "client-activities",
    page_name: "Client Activities Page",
    component: ClientActivities,
    meta: { title: "Clients Activities Page - Express Permits LLC" },
  },
  {
    path: "/profile/",
    name: "profile",
    page_name: "Profile Page",
    component: ProfilePage,
    meta: { title: "Profile Page - Express Permits LLC" },
  },
  {
    path: "/users/",
    name: "users",
    page_name: "Users Page",
    component: UsersPage,
    meta: { title: "Users Page - Express Permits LLC" },
  },
  {
    path: "/bt-settings/",
    name: "bt-settings",
    page_name: "bt-settings Page",
    component: BtPage,
    meta: { title: "bt-settings Page - Express Permits LLC" },
  },
  {
    path: "/super-orders/",
    name: "super_orders",
    page_name: "Orders Page",
    component: SuperAdminOrdersPage,
    meta: { title: "SuperAdmin Orders Page - Express Permits LLC" },
  },
  {
    path: "/super-order/:order_id",
    name: "super-order",
    page_name: "Order Single Page",
    component: AdminOrderPageId,
    meta: { title: "SuperAdmin Order Page - Express Permits LLC" },
  },
  {
    path: "/companies/",
    name: "companies",
    page_name: "Companies Page",
    component: CompaniesPage,
    meta: { title: "Companies Page - Express Permits LLC" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
 document.title = to.meta.title || "Express Permits LLC";
  const store = useStore(); // Get access to the Vuex store
  const user_role = await new Promise((resolve) => {
    setTimeout(() => {
      resolve(store.getters.userRole);
    }, 100);
  });

  const redirects = {
    owner: "/new-order/",
    driver: "/new-order/",
    manager: "/admin-order/",
    admin: "/super-orders/"
  };

  if (redirects.hasOwnProperty(user_role) && to.path === "/") {
    next(redirects[user_role]);
  } else {
    next();
  }
});

export default router;
